import React from 'react';

function Logo({ className = '' }) {
  return (
    <div className={ 'max-w-[150px] font-vollkorn ' + className }>
      <img src="https://img.api.cryptorank.io/coins/human_dao1657897604807.png" alt="DAO logo" width="60" height="250"/>
    </div>

  );
}

export default Logo;
