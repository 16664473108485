import axios from 'axios';

export async function fetch_query(prompt) {
  const url = `https://gpt-backend-app.herokuapp.com/humandao/search?prompt=${prompt}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}
